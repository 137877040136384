<template>
    <div class="footer-copyright text-center py-3 footer_container ">
        <div>
            &copy; 2023 Copyright: <a href="https://damdamglobal.com/"> damdamglobal.com </a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterPage',
}
</script>

<style lang="scss" scoped>
.footer_container {
    background: black !important;
    color: #ffffff;
    font-family: NunitoMedium;
}
</style>